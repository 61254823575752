import { FeaturedPostCard } from 'components/Blog'
import { useBlogPostsQuery } from 'hooks/useBlogPostsQuery'
import Link from 'next/link'
import { ComponentType } from 'react'
import Ellipsis from 'react-ellipsis-pjs'
import { Col, Container, Row } from 'reactstrap'

export const LatestBlogPostsDesktop: ComponentType = () => {
  const { data } = useBlogPostsQuery({
    variables: { query: { limit: 3 } },
  })

  const [post, ...posts] = data?.blogPosts.posts ?? []

  return (
    <Container>
      <Row>
        <Col lg={8} className="d-flex flex-column justify-content-center">
          {post && <FeaturedPostCard post={post} />}
        </Col>
        <Col lg={4} className="d-flex flex-column justify-content-center py-10">
          <h5 className="font-family-serif font-size-17 font-weight-bold px-15 py-20">Más artículos del blog</h5>
          {posts.map((post: any) => (
            <div key={post.id} className="position-relative px-15 py-25 border-top border-athens-gray">
              <Link
                href={{
                  pathname: '/blog/[postSlug]',
                  query: { postSlug: post.slug },
                }}
                as={`/blog/${post.slug}`}
              >
                <a className="card-link stretched-link font-size-17 font-weight-bold mb-5px inversed-link">
                  {post.title}
                </a>
              </Link>
              <div className="mt-10 mb-0 text-trout">
                <Ellipsis text={post.excerpt} lines={2} />
              </div>
            </div>
          ))}
        </Col>
      </Row>
    </Container>
  )
}
