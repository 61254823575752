import { Media } from 'media'
import { ComponentType, Fragment } from 'react'
import { LatestBlogPostsDesktop } from './LatestBlogPostsDesktop'
import { LatestBlogPostsMobile } from './LatestBlogPostsMobile'

export const LatestBlogPosts: ComponentType = () => (
  <Fragment>
    <Media at="xs">
      <LatestBlogPostsMobile />
    </Media>
    <Media greaterThan="xs">
      <LatestBlogPostsDesktop />
    </Media>
  </Fragment>
)
