import { FeaturedPostCard } from 'components/Blog'
import { useBlogPostsQuery } from 'hooks/useBlogPostsQuery'
import { ComponentType } from 'react'
import { Col, Container, Row } from 'reactstrap'

export const LatestBlogPostsMobile: ComponentType = () => {
  const { data } = useBlogPostsQuery({
    variables: { query: { limit: 1 } },
  })

  const post = data?.blogPosts.posts[0]

  return (
    <Container>
      <Row>
        <Col>{post && <FeaturedPostCard post={post} compact />}</Col>
      </Row>
    </Container>
  )
}
